
import {Component, Vue} from 'vue-property-decorator';
import multiSelect from '@/components/multiSelect/index.vue';
import {
  applications,
  applicationRequests,
  payMerchants,
} from '../../../../resources';
import {ObjectId} from 'bson';
import {Form} from 'element-ui';
import {PayMerchant} from '@/externals/MaxCI-PayMerchant-v1';
import {
  ApplicationServiceConfig,
  AgentConfig,
} from '@/externals/MaxCI-Application-v1';
interface IdPayMerchant extends PayMerchant {
  id: string;
}
@Component({
  name: 'errandService',
  components: {
    multiSelect,
  },
})
export default class extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private validprice = (rule: any, value: string, callback: any) => {
    if (!/(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(value)) {
      callback(new Error('价格格式有误'));
    } else {
      callback();
    }
  };
  private validRate = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (!/^(1|(0(.\d+)?))$/.test(value)) {
      callback(new Error('请输入0-1的数值'));
    } else {
      callback();
    }
  };
  private serviceConfigs: null | ApplicationServiceConfig = null;
  private wechatPayMerchantData: Array<IdPayMerchant> = []; //微信支付商户
  private formData = {
    wechat: '', //支付商户
    serviceRate: null as null | number, //服务费率
    serviceGuaranteed: null as null | number, //保底服务费
    //跑腿指导价
    guideFee帮忙: null as null | number,
    guideFee代取: null as null | number,
    guideFee代送: null as null | number,
    guideFee代购: null as null | number,
    //指导最低价
    minimumFee帮忙: null as null | number,
    minimumFee代取: null as null | number,
    minimumFee代送: null as null | number,
    minimumFee代购: null as null | number,
  };
  private rules = {
    wechat: [{required: true, message: '请选择支付商户', trigger: 'blur'}],
    serviceRate: [
      {required: true, message: '请输入服务费率', trigger: 'blur'},
      {validator: this.validRate, trigger: 'blur'},
    ],
    serviceGuaranteed: [
      {required: true, message: '请输入保底服务费', trigger: 'blur'},
      {validator: this.validprice, trigger: 'blur'},
    ],
    guideFee帮忙: [
      {required: true, message: '请输入指导价', trigger: 'blur'},
      {validator: this.validprice, trigger: 'blur'},
    ],
    guideFee代取: [
      {required: true, message: '请输入指导价', trigger: 'blur'},
      {validator: this.validprice, trigger: 'blur'},
    ],
    guideFee代送: [
      {required: true, message: '请输入指导价', trigger: 'blur'},
      {validator: this.validprice, trigger: 'blur'},
    ],
    guideFee代购: [
      {required: true, message: '请输入指导价', trigger: 'blur'},
      {validator: this.validprice, trigger: 'blur'},
    ],
    minimumFee帮忙: [
      {required: true, message: '请输入指导价', trigger: 'blur'},
      {validator: this.validprice, trigger: 'blur'},
    ],
    minimumFee代取: [
      {required: true, message: '请输入指导价', trigger: 'blur'},
      {validator: this.validprice, trigger: 'blur'},
    ],
    minimumFee代送: [
      {required: true, message: '请输入指导价', trigger: 'blur'},
      {validator: this.validprice, trigger: 'blur'},
    ],
    minimumFee代购: [
      {required: true, message: '请输入指导价', trigger: 'blur'},
      {validator: this.validprice, trigger: 'blur'},
    ],
  };
  async created() {
    try {
      //查询微信支付商户
      const payMerchant = await payMerchants.find(stage =>
        stage.$match(match =>
          match(
            f => f('spec')('applicationId'),
            e =>
              e.$eq(
                ObjectId.createFromHexString(this.$route.params.applicationId),
              ),
          )(
            f => f('spec')('type'),
            e => e.$eq('微信'),
          ),
        ),
      );
      if (payMerchant.length > 0) {
        this.wechatPayMerchantData = payMerchant.map(v => {
          return {
            ...v,
            id: v._id.toHexString(),
          };
        });
      }
      const application = (
        await applications.find(stage =>
          stage.$match(match => {
            match(
              f => f('_id'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            );
            return match;
          }),
        )
      ).find(() => true);
      this.serviceConfigs = application?.spec.serviceConfigs ?? null;
      if (this.serviceConfigs?.跑腿?.payMerchants) {
        if (this.serviceConfigs?.跑腿?.payMerchants.wechat) {
          this.formData.wechat =
            this.serviceConfigs?.跑腿?.payMerchants.wechat.toHexString() ?? '';
        }
      }
      this.formData.serviceRate =
        this.serviceConfigs?.跑腿?.serviceRate ?? null;
      this.formData.serviceGuaranteed = this.serviceConfigs?.跑腿
        ?.serviceGuaranteed
        ? this.serviceConfigs?.跑腿?.serviceGuaranteed / 100
        : null;
      //跑腿指导价
      this.formData.guideFee帮忙 = this.serviceConfigs?.跑腿?.guideFee.帮忙
        ? this.serviceConfigs?.跑腿?.guideFee.帮忙 / 100
        : null;
      this.formData.guideFee代取 = this.serviceConfigs?.跑腿?.guideFee.代取
        ? this.serviceConfigs?.跑腿?.guideFee.代取 / 100
        : null;
      this.formData.guideFee代送 = this.serviceConfigs?.跑腿?.guideFee.代送
        ? this.serviceConfigs?.跑腿?.guideFee.代送 / 100
        : null;
      this.formData.guideFee代购 = this.serviceConfigs?.跑腿?.guideFee.代购
        ? this.serviceConfigs?.跑腿?.guideFee.代购 / 100
        : null;
      //指导最低价
      this.formData.minimumFee帮忙 = this.serviceConfigs?.跑腿?.minimumFee.帮忙
        ? this.serviceConfigs?.跑腿?.minimumFee.帮忙 / 100
        : null;
      this.formData.minimumFee代取 = this.serviceConfigs?.跑腿?.minimumFee.代取
        ? this.serviceConfigs?.跑腿?.minimumFee.代取 / 100
        : null;
      this.formData.minimumFee代送 = this.serviceConfigs?.跑腿?.minimumFee.代送
        ? this.serviceConfigs?.跑腿?.minimumFee.代送 / 100
        : null;
      this.formData.minimumFee代购 = this.serviceConfigs?.跑腿?.minimumFee.代购
        ? this.serviceConfigs?.跑腿?.minimumFee.代购 / 100
        : null;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  private submitForm() {
    (this.$refs.formData as Form).validate(valid => {
      if (valid) {
        const agentConfig = {
          payMerchants: {
            wechat: ObjectId.createFromHexString(this.formData.wechat),
          },
          serviceRate: Number(this.formData.serviceRate),
          serviceGuaranteed: Math.round(
            Number(this.formData.serviceGuaranteed) * 100,
          ),
          guideFee: {
            帮忙: Number(this.formData.guideFee帮忙) * 100,
            代取: Number(this.formData.guideFee代取) * 100,
            代送: Number(this.formData.guideFee代送) * 100,
            代购: Number(this.formData.guideFee代购) * 100,
          },
          minimumFee: {
            帮忙: Number(this.formData.minimumFee帮忙) * 100,
            代取: Number(this.formData.minimumFee代取) * 100,
            代送: Number(this.formData.minimumFee代送) * 100,
            代购: Number(this.formData.minimumFee代购) * 100,
          },
        } as AgentConfig;
        let serviceConfigs = null as null | ApplicationServiceConfig;
        if (this.serviceConfigs) {
          if (this.serviceConfigs.跑腿) {
            serviceConfigs = this.serviceConfigs;
            serviceConfigs.跑腿 = agentConfig;
          } else {
            serviceConfigs = {
              ...this.serviceConfigs,
              跑腿: agentConfig,
            };
          }
        } else {
          serviceConfigs = {
            跑腿: agentConfig,
          };
        }
        try {
          applicationRequests
            .create(
              [
                {
                  spec: {
                    type: '更新应用',
                    applicationId: ObjectId.createFromHexString(
                      this.$route.params.applicationId,
                    ),
                    serviceConfigs: serviceConfigs,
                  },
                },
              ],
              {
                watch: {
                  filter: filter =>
                    filter(
                      f => f('operationType'),
                      e => e.$eq('update'),
                    )(
                      f => f('fullDocument')('status')('phase'),
                      e => e.$exists(true),
                    ),
                },
                fullResource: true,
              },
            )
            .then(res => {
              if (res[0].status?.phase === '成功') {
                this.$message.success('保存成功');
              } else {
                this.$message.error(res[0].status?.conditions[0].message ?? '');
              }
            });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          this.$message.error('网络异常，请稍后重试');
        }
      } else {
        return false;
      }
    });
  }
}
