
import {Component, Vue} from 'vue-property-decorator';
import Submenu from '@/components/Submenu/index.vue';
import errandService from './components/errandService/index.vue';

@Component({
  name: 'setting',
  components: {
    Submenu,
    errandService,
  },
})
export default class extends Vue {
  private menu = '';
  private submenu = [
    {
      top: '',
      list: [{name: '跑腿服务', active: true}],
    },
  ];
  created() {
    this.$store.state.applicationId = this.$route.params.applicationId;
    this.menu = this.$route.query.menu
      ? (this.$route.query.menu as string)
      : '跑腿服务';
  }
}
